import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteMpan, RelatedMpanModel, CompleteMprn, RelatedMprnModel, CompleteCompanySite, RelatedCompanySiteModel, CompleteCompany, RelatedCompanyModel, CompleteBillingAddress, RelatedBillingAddressModel, CompleteCompanyContactAddress, RelatedCompanyContactAddressModel, CompleteProfile, RelatedProfileModel } from "./index"

export const EntityAddressModel = z.object({
  /**
   * The private internal unique identifier for this Address resource.
   */
  id: z.string(),
  /**
   * External GUID for the address.
   */
  guid: z.string(),
  /**
   * Unique Property Reference Number.
   */
  uprn: z.string().nullish(),
  /**
   * Unique Delivery Point Reference Number.
   */
  udprn: z.string().nullish(),
  /**
   * The postal code of the address.
   */
  postcode: z.string(),
  /**
   * The county where the address is located.
   */
  county: z.string().nullish(),
  /**
   * The postal town of the address.
   */
  postalTown: z.string().nullish(),
  /**
   * The Public Electricity Supplier (PES) region of the address also known as Distribution Network Operators (DNO).
   */
  pesRegion: z.string().nullish(),
  /**
   * Country of the address.
   */
  country: z.string().nullish(),
  /**
   * Detailed Address string.
   */
  displayName: z.string().nullish(),
  /**
   * EntityAddress line 1 detail.
   */
  addressLine1: z.string().nullish(),
  /**
   * Additional address line 2 detail.
   */
  addressLine2: z.string().nullish(),
  /**
   * Name of the house.
   */
  houseName: z.string().nullish(),
  /**
   * House Number associated with the address.
   */
  houseNumber: z.string().nullish(),
  /**
   * Flat number or identifier in case of a multi-dwelling unit.
   */
  flatNumber: z.string().nullish(),
  /**
   * Indicates whether the address is in the BG service area.
   */
  bgServiceArea: z.boolean(),
  createdById: z.string().nullish(),
  /**
   * Timestamp when this record was created.
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when this record was last updated.
   */
  updatedAt: z.date().optional(),
})

export interface CompleteEntityAddress extends z.infer<typeof EntityAddressModel> {
  mpans: CompleteMpan[]
  mprns: CompleteMprn[]
  companySites: CompleteCompanySite[]
  companies: CompleteCompany[]
  billingAddresses: CompleteBillingAddress[]
  companyContactAddresses: CompleteCompanyContactAddress[]
  createdBy?: CompleteProfile | null
}

/**
 * RelatedEntityAddressModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedEntityAddressModel: z.ZodSchema<CompleteEntityAddress> = z.lazy(() => EntityAddressModel.extend({
  mpans: RelatedMpanModel.array(),
  mprns: RelatedMprnModel.array(),
  companySites: RelatedCompanySiteModel.array(),
  companies: RelatedCompanyModel.array(),
  billingAddresses: RelatedBillingAddressModel.array(),
  companyContactAddresses: RelatedCompanyContactAddressModel.array(),
  createdBy: RelatedProfileModel.nullish(),
}))
