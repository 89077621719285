import * as z from "zod"
import * as imports from "../zod-schemas"
import { SiteStatus } from "@prisma/client"
import { CompleteEntityAddress, RelatedEntityAddressModel, CompleteBillingAddress, RelatedBillingAddressModel, CompleteCompany, RelatedCompanyModel, CompleteCompanyContact, RelatedCompanyContactModel, CompleteNote, RelatedNoteModel, CompleteActivityLog, RelatedActivityLogModel, CompleteSiteMeter, RelatedSiteMeterModel, CompleteContract, RelatedContractModel, CompleteDeal, RelatedDealModel, CompleteCompanyBankingDetail, RelatedCompanyBankingDetailModel, CompleteCallback, RelatedCallbackModel, CompleteCompanyFile, RelatedCompanyFileModel } from "./index"

export const CompanySiteModel = z.object({
  /**
   * Unique identifier for the company site
   */
  id: z.string(),
  /**
   * Company site reference id
   */
  siteRefId: z.number().int(),
  /**
   * Site status
   */
  status: z.nativeEnum(SiteStatus),
  /**
   * Site is a golden record
   */
  isGoldenRecord: z.boolean(),
  /**
   * Site is night data
   */
  isNightData: z.boolean(),
  /**
   * Site is ceaseed
   */
  isCeased: z.boolean(),
  /**
   * Site has done a deal previously
   */
  hasPreviousDeal: z.boolean(),
  entityAddressId: z.string(),
  companyId: z.string(),
  /**
   * Timestamp when the company site was created
   * Timestamp when the company site was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when the company site was last updated
   */
  updatedAt: z.date().optional(),
})

export interface CompleteCompanySite extends z.infer<typeof CompanySiteModel> {
  entityAddress: CompleteEntityAddress
  billingAddresses: CompleteBillingAddress[]
  company: CompleteCompany
  contacts: CompleteCompanyContact[]
  notes: CompleteNote[]
  activities: CompleteActivityLog[]
  siteMeters: CompleteSiteMeter[]
  contracts: CompleteContract[]
  deals: CompleteDeal[]
  banking: CompleteCompanyBankingDetail[]
  callbacks: CompleteCallback[]
  files: CompleteCompanyFile[]
}

/**
 * RelatedCompanySiteModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCompanySiteModel: z.ZodSchema<CompleteCompanySite> = z.lazy(() => CompanySiteModel.extend({
  entityAddress: RelatedEntityAddressModel,
  billingAddresses: RelatedBillingAddressModel.array(),
  company: RelatedCompanyModel,
  contacts: RelatedCompanyContactModel.array(),
  notes: RelatedNoteModel.array(),
  activities: RelatedActivityLogModel.array(),
  siteMeters: RelatedSiteMeterModel.array(),
  contracts: RelatedContractModel.array(),
  deals: RelatedDealModel.array(),
  banking: RelatedCompanyBankingDetailModel.array(),
  callbacks: RelatedCallbackModel.array(),
  files: RelatedCompanyFileModel.array(),
}))
