import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompanyFileType } from "@prisma/client"
import { CompleteCompany, RelatedCompanyModel, CompleteProfile, RelatedProfileModel, CompleteCompanySite, RelatedCompanySiteModel, CompleteSiteMeter, RelatedSiteMeterModel, CompleteWrittenContract, RelatedWrittenContractModel } from "./index"

export const CompanyFileModel = z.object({
  /**
   * Unique identifier for the company file
   */
  id: z.string(),
  /**
   * Original name of the file
   */
  filename: z.string(),
  /**
   * Type of the file
   */
  type: z.nativeEnum(CompanyFileType),
  /**
   * MIME type of the file
   */
  mimeType: z.string(),
  /**
   * Path of the file in storage
   */
  path: z.string(),
  /**
   * Size of the file in bytes
   */
  size: z.number().int(),
  /**
   * Timestamp when the file was deleted
   */
  deletedAt: z.date().nullish(),
  companyId: z.string(),
  createdById: z.string(),
  deletedById: z.string().nullish(),
  /**
   * Timestamp when the file was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when the file was last updated
   */
  updatedAt: z.date().optional(),
})

export interface CompleteCompanyFile extends z.infer<typeof CompanyFileModel> {
  company: CompleteCompany
  createdBy: CompleteProfile
  deletedBy?: CompleteProfile | null
  sites: CompleteCompanySite[]
  siteMeters: CompleteSiteMeter[]
  writtenContracts: CompleteWrittenContract[]
}

/**
 * RelatedCompanyFileModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCompanyFileModel: z.ZodSchema<CompleteCompanyFile> = z.lazy(() => CompanyFileModel.extend({
  company: RelatedCompanyModel,
  createdBy: RelatedProfileModel,
  deletedBy: RelatedProfileModel.nullish(),
  sites: RelatedCompanySiteModel.array(),
  siteMeters: RelatedSiteMeterModel.array(),
  writtenContracts: RelatedWrittenContractModel.array(),
}))
