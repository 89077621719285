import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteEntityAddress, RelatedEntityAddressModel, CompleteCompanyContact, RelatedCompanyContactModel, CompleteProfile, RelatedProfileModel } from "./index"

export const CompanyContactAddressModel = z.object({
  id: z.string(),
  movedInDate: z.date(),
  movedOutDate: z.date().nullish(),
  isCurrent: z.boolean(),
  addressId: z.string(),
  contactId: z.string(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteCompanyContactAddress extends z.infer<typeof CompanyContactAddressModel> {
  address: CompleteEntityAddress
  contact: CompleteCompanyContact
  createdBy: CompleteProfile
}

/**
 * RelatedCompanyContactAddressModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCompanyContactAddressModel: z.ZodSchema<CompleteCompanyContactAddress> = z.lazy(() => CompanyContactAddressModel.extend({
  address: RelatedEntityAddressModel,
  contact: RelatedCompanyContactModel,
  createdBy: RelatedProfileModel,
}))
