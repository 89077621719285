import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteContract, RelatedContractModel, CompleteCompanyFile, RelatedCompanyFileModel } from "./index"

export const WrittenContractModel = z.object({
  id: z.string(),
  contractId: z.string(),
  companyFileId: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteWrittenContract extends z.infer<typeof WrittenContractModel> {
  contract: CompleteContract
  companyFile: CompleteCompanyFile
}

/**
 * RelatedWrittenContractModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedWrittenContractModel: z.ZodSchema<CompleteWrittenContract> = z.lazy(() => WrittenContractModel.extend({
  contract: RelatedContractModel,
  companyFile: RelatedCompanyFileModel,
}))
